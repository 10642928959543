export const appHead = {"meta":[{"charset":"utf-8"},{"name":"title","content":"Concept Collective | Trusted IT Solutions for Businesses in Western Australia"},{"name":"description","content":"Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more."},{"name":"keywords","content":"IT Solutions Western Australia, Managed IT Services, Cybersecurity, Cloud Solutions, Network Optimization, Business Software, Tech Support, IT Consulting"},{"name":"geo.region","content":"AU-WA"},{"name":"geo.placename","content":"Perth"},{"name":"geo.position","content":"-31.9505;115.8605"},{"name":"ICBM","content":"-31.9505, 115.8605"},{"name":"robots","content":"index, follow"},{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"name":"canonical","content":"https://conceptcollective.com.au/"},{"name":"hreflang","content":"en-au"},{"name":"twitter:card","content":"summary_large_image"},{"name":"og:title","content":"Trusted IT Solutions for Businesses in Western Australia | Your IT Company"},{"name":"og:description","content":"Unlock growth with comprehensive IT solutions tailored for businesses in Western Australia. We offer top-notch services including cybersecurity, cloud solutions, network optimization, and more."},{"name":"og:type","content":"website"},{"name":"og:url","content":"https://conceptcollective.com.au/"},{"name":"og:image","content":"https://conceptcollective.com.au/assets/img/gradient.png"},{"name":"schema","content":"https://schema.org/"},{"name":"alt","content":"IT Solutions in Perth"}],"link":[],"style":[],"script":[],"noscript":[],"charset":"utf-8","viewport":"width=device-width, initial-scale=1","title":"Concept Collective"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null